import { CSpinner } from '@coreui/react-pro';
import React from 'react';

// const createStyles = makeStyles<Theme, any>(() => ({
//     backdrop: { zIndex: 1 }
// }));

export const SaveLoader = ({ isSaving }: { isSaving: boolean }) => {
    // const classes = createStyles();
    if(isSaving==true)
    {
        return (

            // TODO
           
             
            <div className='loading'>
            <CSpinner color='info' />
        </div>
            // <Backdrop className={classes.backdrop} open={isSaving} classes={{ root: classes.backdrop }}>
            //     <CircularProgress color="primary" />
            // </Backdrop>
           
          
            // <h1>Loading</h1>
        );
    }
    
};

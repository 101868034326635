import { setAzureEmail, setCurrentUser } from '../configs/state'
import {
  acceptUserInvitation,
  getCurrentUser,
  saveUserConsent,
} from 'src/monitzionApi/me'
import { User } from 'src/monitzionApi/user'

export const refershUserProfile = () => {
  const promise = new Promise<User>((resolve) => {
    getCurrentUser()
      .then((user) => {
        setCurrentUser(user)
        resolve(user)
        setAzureEmail(user?.email)
      })
      .catch((err) => {
        setCurrentUser(null)
        resolve(null)
        setAzureEmail(err?.response?.data?.detail)
      })
  })

  return promise
}

export const acceptInvitation = (invitationKey: string) => {
  const promise = new Promise<User>((resolve) => {
    acceptUserInvitation(invitationKey)
      .then((user) => {
        setCurrentUser(user)
        resolve(user) 
      })
      .catch((err) => {
        setCurrentUser(null)
        resolve(null)
      })
  })

  return promise
}

export const saveConsent = (
  privacyPolicyApproved: boolean,
  termsAndConditionsApproved: boolean,
  marketingConsentApproved: boolean,
) => {
  //console.log('Save Consent Method')
  const promise = new Promise<void>((resolve, reject) => {
    saveUserConsent({
      privacyPolicyApproved,
      termsAndConditionsApproved,
      marketingConsentApproved,
    })
      .then(() => {
        refershUserProfile()
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })

  return promise
}

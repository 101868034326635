import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { getIsLoggedIn } from './services/authService'
import Cookies from 'universal-cookie';
import Invitation from './pages/Invitation'
import Consent from './pages/Consent'
import './GlobalVariables/GlobalVariables'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./pages/Login'))
const isAuthenticated = getIsLoggedIn();
const cookies = new Cookies();

class App extends Component {
  render(): JSX.Element {

    return (
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>

            {/* {(!isAuthenticated || cookies.get('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
            {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="/consent" element={<Consent/>} />}
            {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}

            {cookies.get('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>} */}

            {(!isAuthenticated || localStorage.getItem('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
            {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="/consent" element={<Consent />} />}
            {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}

            {localStorage.getItem('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>}

            <Route path="*" element={<Login />}></Route>

          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App

/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import { REACT_APP_API_URL } from "src/GlobalVariables/GlobalVariables"

const __API_URL__ = REACT_APP_API_URL
//const __API_URL__ = 'https://monitzion-weu-prod-apim.azure-api.net' //-- For Production
//const __API_URL__ = 'https://monitzion-weu-test-apim.azure-api.net' //-- For test
const __API_VERSION__ = '2019-01-02'
const __API_CLIENT_ID__ = 'a90bbfce-2838-4e60-9271-c85b61d7a2a4'

if (!__API_URL__) {
  throw new Error('__API_URL__ is not set!')
}

if (!__API_VERSION__) {
  throw new Error('__API_VERSION__ is not set!')
}

if (!__API_CLIENT_ID__) {
  throw new Error('__API_CLIENT_ID__ is not set!')
}

export const apiUrl = __API_URL__
export const apiVersion = __API_VERSION__
export const apiClientId = __API_CLIENT_ID__

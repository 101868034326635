import apiRequest from '../services/api/apiService'
import HttpMethod from '../services/api/httpMethod'
import { User } from './user'

export interface UserConsents {
  privacyPolicyApproved: boolean
  termsAndConditionsApproved: boolean
  marketingConsentApproved: boolean
}

export const getCurrentUser = () => {
 
  const promise = new Promise<User>((resolve, reject) => {
    const request = apiRequest<any, User>({
      resource: 'api/me',
      method: HttpMethod.GET,
    })

    request
      .then((res) => {
        
        // if(res.data["clientCompanies"].length < 1)
        // {
        //   localStorage.removeItem('tenantId');
        //   //Google Authentication Process
        //   if (sessionStorage.getItem('googletoken') != null) {
        //     sessionStorage.clear();
        //     localStorage.clear();
        //     cookies.remove('googletoken');
        //     cookies.remove('userName');
        //     cookies.remove('email');
        //     cookies.set('issigningoogleclient', 'false', { path: '/' });
        //     window.sessionStorage.setItem('issignin', 'false');
        //     //setanchorEl(null);
        //     resetState();
        //     history('/login');
        //     window.location.reload();
        //   } else {
        //     resetState();
            
        //   }
        //   // const cookies = new Cookies();
        //   if (cookies.get('googletoken') != null) {
        //     cookies.remove('googletoken');
        //     localStorage.clear();
        //     cookies.set('issignin', 'false', { path: '/' });
        //     window.sessionStorage.setItem('issignin', 'false');
        //     setanchorEl(null);
        //     resetState();
        //   } else {
        //     setanchorEl(null);
        //     resetState();
        //     // logout();
        //     // history('/login');
        //     // window.location.reload();
        //     if(sessionStorage.getItem('microsoft') == null || String(sessionStorage.getItem('microsoft')) == "")
        //     {
        //       if(sessionStorage.getItem('microsoft') == null)
        //       {
        //         sessionStorage.setItem('microsoft',"");
        //         logout();
        //         history('/login');
        //         window.location.reload();
        //       }
        //       else
        //       {
               
        //         history('/login');
        //         window.location.reload();
        //       }
        //     }
        //     else
        //     {
             
        //       logout();
        //       sessionStorage.setItem('microsoft',"");
        //     }
        //   }
        // }
        // else
        // {
          resolve(res.data)
        //}
       
      })
      .catch((err) => {
        console.warn('Encountered error fetching user profile', err)
        reject(err)
      })
  })

  return promise
}

export const acceptUserInvitation = (invitationKey: string) => {
 
  const promise = new Promise<User>((resolve, reject) => {
    const request = apiRequest<any, User>({
      resource: `api/me/${invitationKey}`,
      method: HttpMethod.PUT,
    })

    request
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.warn(
          'Encountered error accepting user invitation',
          invitationKey,
          err,
        )
        reject(err)
      })
  })

  return promise
}

export const saveUserConsent = (consents: UserConsents) => {
 
  //console.log('Save User Consent Method')
  const promise = new Promise<void>((resolve, reject) => {
    const request = apiRequest<any, User>({
      resource: 'api/me',
      method: HttpMethod.POST,
      payload: consents,
    })

    request
      .then(() => {
        resolve()
      })
      .catch((err) => {
        console.warn('Encountered error saving user consent', err)
        reject(err)
      })
  })

  return promise
}

export default {
  getCurrentUser,
  acceptUserInvitation,
  saveUserConsent,
}

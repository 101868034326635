import { CacheLocation } from 'msal'

/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */

// TODO
const __CLIENT_INSTANCE__ = '"https://login.microsoftonline.com/"'
const __CLIENT_TENANT__ = '"common"'
//const __CLIENT_ID__ = 'a652e8a3-e5df-4ff4-84f3-9d00fefcfbc7'
const __CLIENT_ID__ = '7b8019c4-cc7b-426d-aa47-eb6e17f5db51'
const __CLIENT_CACHE_LOCATION__ = 'localStorage'
 //const __API_URL__= 'https://monitzion-weu-test-apim.azure-api.net'
const __API_VERSION__ = '"2019-01-02"'
const __API_CLIENT_ID__ = 'a90bbfce-2838-4e60-9271-c85b61d7a2a4'
const __API_SCOPES__ ='".default"'
const __AI_INSTRUMENTATION_KEY__ ='""'
 // '"https://monitzion.com/Monitzion%20Solution%20API/Admin.Access"' //Space-delimited set of scopes to require for back-end
const __GOOGLE_CLIENTID__ =
  '"826839568520-1a61t8o6n2c87lv3aa41ijocuvl64qql.apps.googleusercontent.com"'

if (!__CLIENT_INSTANCE__) {
  throw new Error('__CLIENT_INSTANCE__ is not set!')
}

if (!__CLIENT_TENANT__) {
  throw new Error('__CLIENT_TENANT__ is not set!')
}

if (!__CLIENT_ID__) {
  throw new Error('__CLIENT_ID__ is not set!')
}

if (!__CLIENT_CACHE_LOCATION__) {
  throw new Error('__CLIENT_CACHE_LOCATION__ is not set!')
}

if (!__API_CLIENT_ID__) {
  throw new Error('__API_CLIENT_ID__ is not set!')
}

if (!__API_SCOPES__) {
  throw new Error('__API_SCOPES__ is not set!')
}

if (!__GOOGLE_CLIENTID__) {
  throw new Error('__GOOGLE_CLIENTID__ is not set!')
}
let cacheLocation: CacheLocation | null = null
if (__CLIENT_CACHE_LOCATION__ === 'localStorage') {
  cacheLocation = 'localStorage'
} else if (__CLIENT_CACHE_LOCATION__ === 'sessionStorage') {
  cacheLocation = 'sessionStorage'
} else {
  cacheLocation = 'localStorage'
}

export default {
  instance: __CLIENT_INSTANCE__,
  tenant: __CLIENT_TENANT__,
  clientId: __CLIENT_ID__,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation,
  serviceClientId: __API_CLIENT_ID__,
  serviceScopes: __API_SCOPES__,
  redirectUri: window.location.origin + '/aad-callback',
  googleClientId: __GOOGLE_CLIENTID__,
}

import { CButton } from '@coreui/react-pro';
import { useAtom } from '@dbeining/react-atom';
import React from 'react';
import { useState } from 'react';
import { Navigate, Route, useNavigate } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import CCFormCheckBox from 'src/common/CCFormCheckBox';
import { SaveLoader } from 'src/common/SaveLoader';
import { appState } from 'src/configs/state';
import { getIsLoggedIn, getUser } from 'src/services/authService';
import { saveConsent } from 'src/services/userService';
import { useTypedSelector } from 'src/store';
import monitzionLogo from '../../src/assets/images/Monitzion_logo_big_145hpx.png'

const Consent = () => {
  // const adUser = getUser();
  // const [privacyPolicyApproved, setPrivacyPolicyApproved] = useState<boolean>(false);
  // const [termsAndConditionsApproved, setTermsAndConditionsApproved] = useState<boolean>(false);
  // const [marketingConsentApproved, setMarketingConsentApproved] = useState<boolean>(false);
  // const [error, setError] = useState<string>();
  // const [isSaving, setIsSaving] = useState<boolean>(false);

  // const save = () => {
  //   if (!termsAndConditionsApproved || !privacyPolicyApproved) {
  //     setError('You must consent to the Privacy Policy and Terms and Conditions to continue.');
  //     return;
  //   }

  //   setError(null);
  //   setIsSaving(true);

  //   saveConsent(privacyPolicyApproved, termsAndConditionsApproved, marketingConsentApproved).catch(() =>
  //     setError('Error saving consent, please try again.')
  //   );
  // };

  const isAuthenticated = getIsLoggedIn();
  const adUser = getUser();
  const { user } = useAtom(appState);
  const [privacyPolicyApproved, setPrivacyPolicyApproved] = useState<boolean>(false);
  const [termsAndConditionsApproved, setTermsAndConditionsApproved] = useState<boolean>(false);
  const [marketingConsentApproved, setMarketingConsentApproved] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTypedSelector((state) => state.theme);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!user) {
    return (
      <>
        <h2>Fetching user profile...</h2>
        <SaveLoader isSaving={true} />
      </>
    );
  }

  if (user.privacyPolicyApproved && user.termsAndConditionsApproved) {
    const redirectPath = localStorage.getItem('redirectPath') || "";
    let path = "/";
    if (redirectPath !== "" && redirectPath !== null) {
      path = `/${redirectPath}`;
    }
    return <Navigate to={path} />;
  }

  const save = () => {

    setIsSaving(true);
    if (!termsAndConditionsApproved || !privacyPolicyApproved) {
      setError('You must consent to the Privacy Policy and Terms and Conditions to continue.');
      setIsSaving(false);
      return;
    }

    setError(null);

    saveConsent(privacyPolicyApproved, termsAndConditionsApproved, marketingConsentApproved)
      .then(() => {

        setIsSaving(false);
        const redirectPath = localStorage.getItem('redirectPath') || "";
        if (redirectPath !== "" && redirectPath !== null) {
          navigate(`/${redirectPath}`);
        }
        else {
          navigate("/");
        }
      })
      .catch(() => {
        setIsSaving(false);
        setError('Error saving consent, please try again.')
      });
  };

  return (
    <>
      <SaveLoader isSaving={isSaving} />
      <div style={{ paddingLeft: "100px", paddingRight: '100px' }}>
        <div style={{ textAlign: 'center', marginTop: '5%' }}>
          <span className="w-100 text-left text-md-center pb-4">
            <img alt="image Missing" className="w-40 mx-auto d-block img-fluid" width={500} src={monitzionLogo} />
            <br />
            <h1 style={{ fontWeight: 'bold' }}>
              Almost there
            </h1>
            <p style={{ fontFamily: 'Helvetica', color: 'rgba(7,9,25,.5)', fontWeight: 'bold' }}>
              You are signed in as <strong>{adUser.userName}</strong>
            </p>
          </span>

        </div>
        <Card className="d-block p-3 mx-2 mb-0 mt-2 rounded border-0"
          style={{ paddingLeft: "24px !important", paddingRight: '24px !important', backgroundColor: "#f9f9f9" }}>
          <div>
            <div className="text-muted text-center mb-3">
              <span>In order to use MONITZION® Solution, you need to agree our:​</span>
            </div>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                name="checkbox"
                className={theme === "dark" ? "CheckBoxClass CheckBoxClassDark me-2" : "CheckBoxClass me-2"}
                onChange={(e) => { setPrivacyPolicyApproved(!privacyPolicyApproved) }}
                style={{ float: "left" }}
              />

              <a style={{ float: "left", color: "blue", textDecoration: "none" }} href="https://www.monitzion.com/privacypolicy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </div>


            <div style={{ height: "30px" }}></div>

            <div>
              <input
                type="checkbox"
                name="checkbox"
                className={theme === "dark" ? "CheckBoxClass CheckBoxClassDark me-2 mt-2" : "CheckBoxClass me-2 mt-2"}
                onChange={(e) => { setTermsAndConditionsApproved(!termsAndConditionsApproved) }}
                style={{ float: "left" }}
              />
              <a className='mt-2' style={{ float: "left", color: "blue", textDecoration: "none" }} href="https://www.monitzion.com/privacypolicy/" target="_blank" rel="noreferrer">
                Terms and conditions to use the MONITZION® Solution.
              </a>
            </div>

            <div style={{ height: "30px" }}></div>
            <div style={{ marginTop: "11px" }}>
              <label>To keep you up to date about products and services and provide inspiration for business and competency development​​</label>
            </div>

            <div>
              <label style={{ cursor: "pointer" }} className='mt-2'>
                <input
                  type="checkbox"
                  name="checkbox"
                  className={theme === "dark" ? "CheckBoxClass CheckBoxClassDark me-2" : "CheckBoxClass me-2"}
                  onChange={(e) => { setMarketingConsentApproved(!marketingConsentApproved) }}
                  style={{ float: "left" }}
                />
                Monitzion may send me promotions and offers about products and services for business.​​</label>
            </div>

            <label className='mt-2'>By choosing Start you acknowledge that your email address is associated with an organization (and is not a personal use or consumer email address). You also understand an administrator of your organization may assume control over your account and data and that your name, email address, and organization name will be visible to other people in your organization and in the MONITZION® Platform related to your activities in the MONITZION® user community.​​​</label>
            <CButton color="dark" className='mt-2' variant="outline"
              onClick={save}>Start 🠊</CButton>
            <br />
            {!!error ? (

              <p style={{ color: "#f95161" }} className="mt-2">{error}</p>

            ) : null}
          </div>
        </Card>
      </div >
    </>
  );
};

export default Consent;
